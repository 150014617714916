import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import {
  Row, Col, Table, Container, Button, Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import Pagination from './commons/pagination';
import { paginate } from './utils/paginate';
import { getTransaction, addNewUser } from '../../../reducers/home';
import { navPermission } from '../../../actions/permission';
import { getPendingPostings, approvePendingPosting } from '../../../actions/postingAction';
import PostingPagination from '../../../Utils/PostingPagination';
import { useDispatch, useSelector } from "react-redux";
import Loader from './Loader';
import postingInfo from './utils/postingInfo.json'

const PendingPostings = () => {

  const dispatch = useDispatch();

  const [accountName, setAccountName] = useState("Account Name")
  const [accountNumber, setAccountNumber] = useState("Account Number")
  const [amount, setAmount] = useState("Amount")
  const [teller_no, setTeller_no] = useState("Teller No")
  const [trans_type, setTrans_type] = useState("Trans Type")
  const [pageSize, setPageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [approveModalOpen, setApproveModalOpen] = useState(false)
  const [currentPostingInfo, setCurrentPostingInfo] = useState({})

  const pendingPostings = useSelector((state) => state.pendingPostings);
  const { loading, success, error, pendingPostings: pendingPostingsArray } = pendingPostings;

  const home = useSelector((state) => state.home);
  const { showGetTransaction } = home;

  useEffect(() => {
    if (!navPermission(2)) {
      return (window.location.href = '/');
    } else {
      const { user } = window.localStorage;
      const { org_id: orgId } = JSON.parse(user);
      dispatch(getTransaction({ orgId }))
      dispatch(getPendingPostings({ limit: 100 }))
    }
  }, [dispatch])

  const populateTransactions = showGetTransaction => {
    let startId = 1;
    const transactionsList = showGetTransaction.map(transaction => {
      const {
        // accountNumber,
        status,
        account_number,
        amount,
        teller_cheque_no,
        trans_type,
        account_name,
        id,
        created_at
      } = transaction;

      return (
        <tr className='tr' key={id}>
          <th scope='row'>{startId++}</th>
          <td>{account_name}</td>
          <td>{account_number}</td>
          <td>{`NGN ${amount}`}</td>
          <td>{teller_cheque_no}</td>
          <td className='text-center'>
            <strong
              className={`${trans_type === 'CR' ? 'text-success' : 'text-danger'
                }`}
            >
              <span className='mr-2'>
                {trans_type === 'CR' ? (
                  <i className='fas fa-level-up-alt'></i>
                ) : (
                  <i className='fas fa-level-down-alt'></i>
                )}
              </span>
              {trans_type}
            </strong>
          </td>
          <td>{created_at}</td>
          <td>
            <Button
              outline
              color="success"
              className="form-control bg-success text-white font-weight-bold text-uppercase"
              onClick={() => toggleModal(id, amount)}
            >
              Approve
            </Button>
          </td>
        </tr>
      );
    });
    return transactionsList;
  };

  const handlePageChange = page => {
    setCurrentPage(page)
  };
  const toggleModal = (id, amount) => {
    setApproveModalOpen(!approveModalOpen)
    setCurrentPostingInfo({ id, amount })
    console.log(amount)
  };
  const handleSuccess = async () => {
    const postingId = Number(currentPostingInfo.id)
    const userInfoFromStorage = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;
    if (
      (userInfoFromStorage.user_type != "Authorizer" && userInfoFromStorage.user_type != "Super_Admin")
      ||
      currentPostingInfo.amount > postingInfo.postingLimit && (userInfoFromStorage.user_type != "Authorizer" && userInfoFromStorage.user_type != "Super_Admin") ||
      (currentPostingInfo.amount > postingInfo.authorizationLimit && userInfoFromStorage.user_type != "Super_Admin")) {
      toast.error("Not Authorized")
      setApproveModalOpen(false)
    } else {
      const res = await approvePendingPosting(postingId, userInfoFromStorage.email)
      setApproveModalOpen(false);

      console.log("res", res);
     
      toast.success("Approved successfully")
      window.location.reload()

      // if(res){
      // }


      // if (res.status == "failed") {
      //   toast.error("Failed to approve")
      // } else {
      //   toast.success("Approved successfully")
      //   window.location.reload()
      // }
    }

  };
  const datas = paginate(showGetTransaction, currentPage, pageSize);
  let data = pendingPostingsArray ? pendingPostingsArray.postings : [];
  let _pagination = pendingPostingsArray ? pendingPostingsArray.pagination : {};
  _pagination = { ..._pagination, movement: 3, range: 5 };
  // console.log(datas, 'data');
  console.log(data, 'dataaaaaaaaaaaaaaaaaaaaaa');
  return (
    <Container>
      <Row>
        <Col>
          <PostingPagination {..._pagination} caller={getPendingPostings} />
        </Col>

      </Row>
      {loading &&
        <Loader />
      }
      <Row>
        <Modal isOpen={approveModalOpen} toggle={() => toggleModal()}>
          <ModalHeader toggle={() => toggleModal()}>
            Confirm
          </ModalHeader>
          <ModalBody>Are you sure you want approve?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => handleSuccess()}>
              Yes
            </Button>{" "}
            <Button color="primary" outline onClick={() => toggleModal()}>
              No
            </Button>{" "}
          </ModalFooter>
        </Modal>
        {error ?
          <Col>
            <strong>
              Something went wrong
            </strong>
          </Col> :
          <Col>
            <Table borderless>
              <thead>
                <tr className='text-uppercase'>
                  <th></th>
                  <th>
                    <small className='font-weight-bold'>Account Name</small>
                  </th>
                  <th>
                    <small className='font-weight-bold'>Account Number</small>
                  </th>
                  <th>
                    <small className='font-weight-bold'>Amount</small>
                  </th>
                  <th>
                    <small className='font-weight-bold'>Teller No</small>
                  </th>
                  <th>
                    <small className='font-weight-bold'>Trans type</small>
                  </th>
                  <th>
                    <small className='font-weight-bold'>Date</small>
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* {showGetTransaction && this.populateTransactions(datas)} */}
                {data && populateTransactions(data)}
              </tbody>
            </Table>
          </Col>
        }
      </Row>
      <Row>
        <Col>
          {showGetTransaction && (
            <Pagination
              itemCount={showGetTransaction.length}
              pageSize={pageSize}
              onPageChange={handlePageChange}
              currentPage={currentPage}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <PostingPagination {..._pagination} caller={getPendingPostings} />
        </Col>
      </Row>
    </Container>
  )
}
export default PendingPostings