import React, { useState, useEffect, useCallback } from "react";
// import { debounce } from 'lodash'
import { connect } from "react-redux";
import { getAccountName } from "../../../reducers/transfers";
import {
  Col,
  Container,
  Row,
  Form,
  Input,
  FormGroup,
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Loader from "./Loader";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { getAccountDetails, getOperationDate, getPostingAccountDetails } from "../../../actions/postingAction";
import "../../../assets/js/scripts.js";
import { createPosting, toggleModal } from "../../../actions/postingAction";

const ChequeTransaction = (props) => {
  const { user } = window.localStorage;
  const { org_id: orgId, staff_id } = JSON.parse(user);

  const [loading, setLoading] = useState(false);
  const [inHouseSelected, setinHouseSelected] = useState(false)
  const [usingName, setUsingName] = useState(false)
  const [searchedAccounts, setSearchedAccounts] = useState([])
  const [singleSearchedAccount, setSingleSearchedAccount] = useState({})
  const [selectedIndex, setSelectedIndex] = useState('')

  const formik = useFormik({
    initialValues: {
      accountNameCheck: "TBA",
      useAccount: "",
      accountNumber: "",
      accountName: "",
      accountBalance: "",
      operationDate: "",
      transactionType: "",
      valueDate: "",
      chequeNo: "",
      amount: "",
      naration: "",
      GLAccountCode: "",
      inHouseDebitAccount: "",
      accountTitle: "",
      stampDutyCharge: "",
      staffId: "",
      //staff_id
    },
    validationSchema: Yup.object({
      // accountNameCheck: Yup.string().required(
      //   "Account name check value is required"
      // ),
     // staffId: Yup.string().required("staff Id value is required"),
      useAccount: Yup.string().required("Checkbox value is required"),
      accountNumber: Yup.string().required("Account number is required"),
      accountBalance: Yup.string().required("Account balance is required"),
      accountName: Yup.string().required("Account name is required"),
      operationDate: Yup.string().required("Operation date is required"),
      transactionType: Yup.string().required("Transaction type is required"),
      valueDate: Yup.string().required("Value date is required"),
      chequeNo: Yup.string().required("Cheque no is required"),
      amount: Yup.string().required("Transaction amount is required"),
      naration: Yup.string()
        .max(50, "Must be 50 characters or less")
        .required("Naration is required"),
      GLAccountCode: Yup.string().when("transactionType", (transactionType) => {
        if (transactionType !== 'In-House Cheque') {
          return Yup.string().required("GL account is required")
        }

      }),
      // inHouseDebitAccount: Yup.string().required('In House Debit account is required'),
      inHouseDebitAccount: Yup.string().when("transactionType", (transactionType) => {
        if (transactionType === 'In-House Cheque') {
          return Yup.string().required("In-House debit account is required")
        }

      }),
      accountTitle: Yup.string()
        .max(50, "Must be 50 characters or less")
        .required("Account title is required"),
    }),
    onSubmit: (values) => {
      console.log(values)
      props.createPosting({
        staff_id: staff_id,
        use_account: values.useAccount,
        account_name_check: values.accountNameCheck,
        naration: values.naration,
        account_number: values.accountNumber,
        balance: values.accountBalance,
        account_name: values.accountName,
        operation_date: values.operationDate,
        amount: values.amount,
        transaction_type: values.transactionType,
        teller_cheque_no: values.chequeNo,
        value_date: values.valueDate,
        GL_account_code_id: values.GLAccountCode,
        // inHouseDebitAccount: values.inHouseDebitAccount,
        account_title: values.accountTitle,
        stamp_duty_charge: values.stampDutyCharge
      });
    },
  });

  // fieldNameValue: nuban or internal
  const handleUseAccount = async (event, fieldNameValue) => {
    const checked = event.target.checked;

    if (checked === true ) {

      formik.setFieldValue("useAccount", fieldNameValue, true);
      setLoading(true);

      let responseData;
      if(usingName) {
      responseData = await getAccountDetails(
        singleSearchedAccount.nuban,
        orgId,
        false,
        true
      );
      }
      if(responseData) {
      const { getAccountName: { balance } } = responseData
      formik.setFieldValue(
        "accountBalance",
        balance,
        true
      );
      setLoading(false);
      if (fieldNameValue === 'NUBAN' && usingName) {
        formik.setFieldValue("accountNumber", singleSearchedAccount.nuban, true);
      }
      if (fieldNameValue === 'Internal' && usingName) {
        formik.setFieldValue("accountNumber", singleSearchedAccount.internal, true);
      }
    }
    setLoading(false);
    } else {
      formik.setFieldValue("useAccount", "", true);
      setLoading(false);
    }
  };

  const handleSuccess = () => {
    props.toggleModal();
    props.history.push("/transactions/accountOpening");
  };

  //Debounce function
  // const debouncedFunc = useCallback(debounce(nextValue => getPostingAccountDetails(
  //   'name', nextValue
  // ), 1000), [])

  const handleSearchedAccountSelectChange = (e) => {
    setSelectedIndex(e.target.value)
    setSingleSearchedAccount(searchedAccounts[e.target.value])
    setUsingName(true)
    formik.setFieldValue(
      "accountName",
      searchedAccounts[e.target.value].fullname,
      true
    );
    console.log(searchedAccounts[e.target.value])
  }

  const handleSearchByAccountName = async (event) => {

    formik.setFieldValue("accountNameCheck", event.target.value, true);

    if (event.target.value.length > 6) {
      setLoading(true);
      setUsingName(true)

      const res = await getPostingAccountDetails({
        search_key: 'name',
        value: event.target.value,
        limit: 100,
        page: 1
      })
      const { getPostingAccountDetails: { accounts } } = res
      console.log(accounts)
      // if (accounts.length > 0) {
      if (accounts) {
        setSearchedAccounts(accounts)
      } else {
        setLoading(false);
        toast.error("No Result(s)");
      }
      setLoading(false);

    }
  }

  const handleAccountDetails = async (event) => {
    formik.setFieldValue("accountNumber", event.target.value, true);
    setUsingName(false)
    if (event.target.value.length > 4) {
      setLoading(true);
      const responseData = await getAccountDetails(
        event.target.value,
        orgId,
        false,
        true
      );
      if (responseData && responseData.getAccountName.balance) {
        formik.setFieldValue(
          "accountName",
          responseData.getAccountName.acct_name,
          true
        );
        formik.setFieldValue(
          "accountBalance",
          responseData.getAccountName.balance,
          true
        );
      } else {
        setLoading(false);
        toast.error("Invalid account number");
      }
      setLoading(false);
    }
  };


  const handleAccountDetailsGL = async (event) => {
    formik.setFieldValue("GLAccountCode", event.target.value, true);
    setUsingName(false)
    if (event.target.value.length > 4) {
      setLoading(true);
      const responseData = await getAccountDetails(
        event.target.value,
        orgId,
        false,
        true
      );
      if (responseData && responseData.getAccountName.balance) {
        formik.setFieldValue(
          "accountTitle",
          `${responseData.getAccountName.acct_name}`,
          true
        );
      } else {
        setLoading(false);
        toast.error("Invalid account number");
      }
      setLoading(false);
    }
  };

  const handleTransactionTypeChange = (e) => {
    formik.setFieldValue("transactionType", e.target.value, true);
    if (e.target.value === 'In-House Cheque') {
      setinHouseSelected(true)
    } else {
      setinHouseSelected(false)
    }

  }

  const handleGetOperationDate = async () => {
    setLoading(true);
    const responseData = await getOperationDate(setLoading);
    if (responseData.getOperationDate) {
      formik.setFieldValue(
        "operationDate",
        responseData.getOperationDate,
        true
      );
    }
    setLoading(false);
  };

  const toggleModal = () => {
    props.toggleModal();
  };

  useEffect(() => {
    handleGetOperationDate();
  }, [])

  return (
    <Container>
      {loading | props.posting.pageLoading && <Loader />}
      <Row className="justify-content-center login-container align-items-center">
        <Modal isOpen={props.posting.openModal} toggle={() => toggleModal()}>
          <ModalHeader toggle={() => toggleModal()}>
            Cheque Transaction Successfull
          </ModalHeader>
          <ModalBody>Cheque Transaction Posting successfully created.</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => handleSuccess()}>
              Okay
            </Button>{" "}
          </ModalFooter>
        </Modal>
        <Col xs={12} md={12} lg={10}>
          <Form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <div className="text-center mb-4">
                <h6 className="h3 font-weight-normal text-success">
                  Cheque Transaction Entry
                </h6>
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="accountNameCheck">
                <small className="font-weight-bold">Account Name Check</small>
              </Label>
              <Input
                type="text"
                name="accountNameCheck"
                id="accountNameCheck"
                className="form-control"
                onChange={handleSearchByAccountName}
                onBlur={formik.handleBlur}
                value={formik.values.accountNameCheck}
              />
              {formik.touched.accountNameCheck &&
                formik.errors.accountNameCheck ? (
                <div className="text-danger small">
                  {formik.errors.accountNameCheck}
                </div>
              ) : null}
            </FormGroup>
            {searchedAccounts.length > 0 && (
              <FormGroup>
                <Label for="searchedAccounts">
                  <small className="font-weight-bold">Searched Accounts</small>
                </Label>
                <Input
                  type="select"
                  name="searchedAccounts"
                  id="searchedAccounts"
                  className="form-control"
                  onChange={handleSearchedAccountSelectChange}
                  value={selectedIndex}
                >
                  <option value="" disabled>
                    Please Choose...
                  </option>
                  {searchedAccounts.map((account, index) => (
                    <option value={index} key={index}>{account.fullname}</option>
                  ))}
                </Input>
              </FormGroup>
            )}


            <FormGroup>
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    checked={formik.values.useAccount === "Internal"}
                    id="useAccount"
                    name="useAccount"
                    onChange={(event) => handleUseAccount(event, "Internal")}
                    onBlur={formik.handleBlur}
                  />
                  <label class="form-check-label" for="useAccount">
                    <small>Use Internal Account</small>
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    checked={formik.values.useAccount === "NUBAN"}
                    id="useAccount"
                    name="useAccount"
                    onChange={(event) => handleUseAccount(event, "NUBAN")}
                    onBlur={formik.handleBlur}
                  />
                  <label class="form-check-label" for="flexCheckChecked">
                    <small>Use NUBAN Account</small>
                  </label>
                </div>
              </div>

              {/* {formik.touched.useAccount && formik.errors.useAccount ? (
                <div className="text-danger small">
                  {formik.errors.useAccount}
                </div>
              ) : null} */}
              {usingName ? (
                <div className="text-danger small">
                  Checkbox value is required
                </div>
              ) : null}
            </FormGroup>

            <div className="form-row">
              <div className="col-sm-4">
                <FormGroup>
                  <Label for="accountNumber">
                    <small className="font-weight-bold">Account Number</small>
                  </Label>
                  <Input
                    id="accountNumber"
                    name="accountNumber"
                    className="form-control"
                    onChange={handleAccountDetails}
                    onBlur={formik.handleBlur}
                    required
                    type="text"
                    value={formik.values.accountNumber}
                  />
                  {formik.touched.accountNumber && formik.errors.accountNumber ? (
                    <div className="text-danger small">
                      {formik.errors.accountNumber}
                    </div>
                  ) : null}
                </FormGroup>
              </div>
              <div className="col-sm-4">
                <FormGroup>
                  <Label for="accountBalance">
                    <small className="font-weight-bold">Account Balance</small>
                  </Label>
                  <Input
                    name="accountBalance"
                    id="accountBalance"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.accountBalance}
                    required
                    disabled
                    type="text"
                  />
                  {formik.touched.accountBalance && formik.errors.accountBalance ? (
                    <div className="text-danger small">
                      {formik.errors.accountBalance}
                    </div>
                  ) : null}
                </FormGroup>
              </div>
              <div className="col-sm-4">
                <FormGroup>
                  <Label for="accountNumber">
                    <small className="font-weight-bold">Account name</small>
                  </Label>
                  <Input
                    id="accountname"
                    name="accountname"
                    className="form-control"
                    onChange={handleAccountDetails}
                    onBlur={formik.handleBlur}
                    required
                    type="text"
                    value={formik.values.accountName}
                    disabled
                  />
                  {formik.touched.accountName && formik.errors.accountName ? (
                    <div className="text-danger small">
                      {formik.errors.accountName}
                    </div>
                  ) : null}
                </FormGroup>
              </div>
            </div>
            <div className="form-row">
              <div className="col-sm-4">
                <FormGroup>
                  <Label for="operationDate">
                    <small className="font-weight-bold">Operation Date</small>
                  </Label>
                  <Input
                    id="operationDate"
                    name="operationDate"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.operationDate}
                    readOnly={true}
                    type="date"
                  />
                  {formik.touched.operationDate && formik.errors.operationDate ? (
                    <div className="text-danger small">
                      {formik.errors.operationDate}
                    </div>
                  ) : null}
                </FormGroup>
              </div>
              <div className="col-sm-4">
                <FormGroup>
                  <Label for="transactionType">
                    <small className="font-weight-bold">Transaction Type</small>
                  </Label>
                  <Input
                    type="select"
                    name="transactionType"
                    id="transactionType"
                    className="form-control"
                    onChange={handleTransactionTypeChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.transactionType}
                  >
                    <option value="" disabled>
                      Please Choose...
                    </option>
                    <option>Internal Transaction Credit</option>
                    <option>In-House Cheque</option>
                    <option>Local Cheque</option>
                    {/* <option>Up-Country Cheque</option> */}
                    <option>Draft and Cheques Issued</option>
                    {/* <option>Returned Cheque (uncleared)</option> */}
                    <option>Bank Charges</option>
                    {/* <option>Staff Salaries</option> */}
                    <option>Internal Transactions Debit</option>

                  </Input>
                  {formik.touched.transactionType &&
                    formik.errors.transactionType ? (
                    <div className="text-danger small">
                      {formik.errors.transactionType}
                    </div>
                  ) : null}
                </FormGroup>
              </div>
              <div className="col-sm-4">
                <FormGroup>
                  <Label for="valueDate">
                    <small className="font-weight-bold">Value Date</small>
                  </Label>
                  <Input
                    id="valueDate"
                    name="valueDate"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.valueDate}
                    required
                    type="date"
                  />
                  {formik.touched.valueDate && formik.errors.valueDate ? (
                    <div className="text-danger small">
                      {formik.errors.valueDate}
                    </div>
                  ) : null}
                </FormGroup>
              </div>
            </div>
            <div className="form-row">
              <div className="col-sm-4">
                <FormGroup>
                  <Label for="chequeNo">
                    <small className="font-weight-bold">Instr / Serial / Cheque Number</small>
                  </Label>
                  <Input
                    id="chequeNo"
                    name="chequeNo"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.chequeNo}
                    type="number"
                  />
                  {formik.touched.chequeNo && formik.errors.chequeNo ? (
                    <div className="text-danger small">
                      {formik.errors.chequeNo}
                    </div>
                  ) : null}
                </FormGroup>
              </div>
              <div className="col-sm-4">
                <FormGroup>
                  <Label for="Amount">
                    <small className="font-weight-bold">Amount</small>
                  </Label>
                  <Input
                    id="amount"
                    name="amount"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.amount}
                    type="number"
                  />
                  {formik.touched.amount && formik.errors.amount ? (
                    <div className="text-danger small">{formik.errors.amount}</div>
                  ) : null}
                </FormGroup>
              </div>
              <div className="col-sm-4">
                <FormGroup>
                  <Label for="naration">
                    <small className="font-weight-bold">Naration</small>
                  </Label>
                  <Input
                    id="naration"
                    name="naration"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.naration}
                    type="text"
                  />
                  {formik.touched.naration && formik.errors.naration ? (
                    <div className="text-danger small">
                      {formik.errors.naration}
                    </div>
                  ) : null}
                </FormGroup>
              </div>
            </div>
            <div className="form-row">
              <div className="col-sm-4">
                {inHouseSelected ? (
                  <FormGroup>
                    <Label for="GLAccountCode">
                      <small className="font-weight-bold">In-House Debit Account</small>
                    </Label>
                    <Input
                      type="select"
                      name="inHouseDebitAccount"
                      id="InHouseDebitAccount"
                      className="form-control"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.inHouseDebitAccount}
                    >
                      <option value="" disabled>
                        Please Choose...
                      </option>
                      <option>100240</option>
                    </Input>
                    {formik.touched.inHouseDebitAccount && formik.errors.inHouseDebitAccount ? (
                      <div className="text-danger small">
                        {formik.errors.inHouseDebitAccount}
                      </div>
                    ) : null}
                  </FormGroup>
                ) : (
                  <FormGroup>
                    <Label for="GLAccountCode">
                      <small className="font-weight-bold">GL Account Code</small>
                    </Label>
                    <Input
                      type="text"
                      name="GLAccountCode"
                      id="GLAccountCode"
                      className="form-control"
                      onChange={handleAccountDetailsGL}
                      onBlur={formik.handleBlur}
                      value={formik.values.GLAccountCode}
                    />
                    {formik.touched.GLAccountCode && formik.errors.GLAccountCode ? (
                      <div className="text-danger small">
                        {formik.errors.GLAccountCode}
                      </div>
                    ) : null}
                  </FormGroup>
                )}

              </div>
              <div className="col-sm-4">
                <FormGroup>
                  <Label for="accountTitle">
                    <small className="font-weight-bold">Account Title</small>
                  </Label>
                  <Input
                    id="accountTitle"
                    name="accountTitle"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.accountTitle}
                    type="text"
                  />
                  {formik.touched.accountTitle && formik.errors.accountTitle ? (
                    <div className="text-danger small">
                      {formik.errors.accountTitle}
                    </div>
                  ) : null}
                </FormGroup>

              </div>
              <div className="col-sm-4">
                <FormGroup>
                  <Label for="stampDutyCharge">
                    <small className="font-weight-bold">Stamp Duty Charge</small>
                  </Label>
                  <Input
                    type="select"
                    name="stampDutyCharge"
                    id="stampDutyCharge"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.stampDutyCharge}
                  >
                    <option value="" disabled>
                      Please Choose...
                    </option>
                    <option>Yes</option>
                    <option>No</option>
                  </Input>
                  {formik.touched.stampDutyCharge &&
                    formik.errors.stampDutyCharge ? (
                    <div className="text-danger small">
                      {formik.errors.stampDutyCharge}
                    </div>
                  ) : null}
                </FormGroup>
              </div>
            </div>
            <FormGroup>
              <Button
                disabled={props.accounts.pageLoading}
                outline
                color="success"
                className="form-control bg-success text-white font-weight-bold text-uppercase"
                type="submit"
                value="Submit"
              >
                Create Transaction
              </Button>
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  const { accounts, posting } = state;
  return { accounts, posting };
};

const mapDispatchToProps = {
  createPosting,
  getAccountName,
  toggleModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChequeTransaction);
